import Vue from "vue";
import axios from "axios";
import _ from "lodash";

import moment from "moment";
var Url = require("url-parse");

import app from "@/main";

var url = new Url(window.location.href, true);
let b = process.env.VUE_APP_ROOT_API;
// let b = "http://localhost:8000/api";
// let b = "https://a1timesheet.geniusits.com/api/";
if (url.query.token) {
  axios.defaults.headers.common["Authorization"] = url.query.token;
  sessionStorage.setItem("token", url.query.token);
}

export default {
  baseUrl: b,
  http: (() => {
    let token = sessionStorage.getItem("token") || "";
    const instance = axios.create({
      headers: {
        Authorization: `Bearer ${token}`.replace(/"/, ""),
      },
      baseURL: b,
    });
    instance.interceptors.request.use(
      function (config) {
        let token = sessionStorage.getItem("token");
        if (token) {
          config.headers.Authorization = `Bearer ${token}`.replace(/"/, "");
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    instance.interceptors.response.use(
      (resp) => {
        return resp;
      },
      (err) => {
        if (err.response.status == 401) {
          // console.log(err);
          sessionStorage.removeItem("token");
          app.$router.push("/login");
        } else {
          return Promise.reject(err);
        }
      }
    );
    return instance;
  })(),

  event: new Vue(),
  loader: (value) =>
    value == 0
      ? (app.$store.state.loader = false)
      : (app.$store.state.loader = true),
  loginUsingToken: (token) => sessionStorage.setItem("token", token),
  camelCase: (val) => _.startCase(_.toLower(val)),
  upperCase: (val) => _.upperCase(val),
  notify: (status, message) => {
    Vue.notify({
      group: "custom-template",
      title: "Alert !",
      text: message,
      type: status == 1 ? "success" : "error",
    });
  },
  formatTime(time) {
    if (time == null) return null;
    return moment(time, ["h:mm A"]).format("HH:mm");
  },
  //   validation: {
  //     required: (value) => !!value || "Required.",
  //     number: (value) => !isNaN(value) || "Must be a Number",
  //     postalLength: (value) => value > 999 || "Invalid Postal Code",
  //     email: (value) => {
  //       const pattern =
  //         /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,4}\.[0-9]{1,4}\.[0-9]{1,4}\.[0-9]{1,4}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //       return pattern.test(value) || "Invalid e-mail.";
  //     },
  //   },
  input: {
    basic: {
      dense: true,
      outlined: true,
      "hide-details": "auto",
    },
  },
  
};
