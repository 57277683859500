import Vue from "vue";
import VueRouter from "vue-router";
import store from "./../store";
import util from "@/util/util.js";

const Login = () => import("@/components/Login.vue");

//extra
const HomePage = () => import("@/views/HomePage.vue");
const Dashboard = () => import("@/components/admin/Dashboard.vue");
const Unauthorized = () => import("@/components/admin/extra/Unauthorized.vue");
const Landing = () => import("@/components/admin/extra/landing.vue");

//user
const Users = () => import("@/components/admin/users/index.vue");
const ActiveUsers = () => import("@/components/admin/users/active.vue");
const InactiveUsers = () => import("@/components/admin/users/inactive.vue");
const EditUser = () => import("@/components/admin/users/edit.vue");
const AssignRole = () => import("@/components/admin/users/assign-role.vue");
const CreateUser = () => import("@/components/admin/users/create.vue");
const ChangeUserPassword = () => import("@/components/admin/users/changepassword.vue");
const DeletedUserList = () => import("@/components/admin/users/deleted.vue");
const AssignSiteToUser = () => import("@/components/admin/users/assign-site.vue");


//role
const RoleList = () => import("@/components/admin/role/index.vue");
const CreateRole = () => import("@/components/admin/role/create.vue");
const EditRole = () => import("@/components/admin/role/edit.vue");
const AssignPermission = () => import("@/components/admin/role/assign-permission.vue");


//timesheet
const TimesheetDateRange = () => import("@/components/admin/timesheet/daterange.vue");
const EditTimesheet = () => import("@/components/admin/timesheet/edit.vue");

//logged user section
// timesheet
const MyTimesheet = () => import("@/components/user/timesheet/index.vue");
const EditMyTimesheet = () => import("@/components/user/timesheet/edit.vue");
const CreateTimesheet = () => import("@/components/user/timesheet/create.vue");

const MyAttendance = () => import("@/components/user/attendance/index.vue");

const ResetPassword = () => import("@/components/user/password/ResetPassword.vue");
const ChangePassword = () => import("@/components/user/password/ChangePassword.vue");

const ForgotPassword = () => import("@/components/user/password/ForgotPassword.vue");


// Attendance
const AttendanceDateRange = () => import("@/components/admin/attendance/daterange.vue");
const EditAttendance = () => import("@/components/admin/attendance/edit.vue");
const AttendanceDeletedList = () => import("@/components/admin/attendance/deleted.vue");

// today
const TodaysAttendance = () => import("@/components/admin/attendance/today.vue");


//site
const SiteList = () => import("@/components/admin/site/index.vue");
const CreateSite = () => import("@/components/admin/site/create.vue");
const EditSite = () => import("@/components/admin/site/edit.vue");
const AssignUsers = () => import("@/components/admin/site/assign-users.vue");
const UpdateCoordinate = () => import("@/components/admin/site/update-coordinate.vue");

// site duty
const SiteDutyList = () => import("@/components/admin/site/site-duty/index.vue");
const CreateSiteDuty = () => import("@/components/admin/site/site-duty/create.vue");
const EditSiteDuty = () => import("@/components/admin/site/site-duty/edit.vue");
// const AdminHome = () => import("../components/admin/AdminHome.vue");

//incident report
const IncidentReportList = () => import("@/components/admin/incident-report/index.vue");
const IncidentReportDetail = () => import("@/components/admin/incident-report/detail.vue");

//checklist report
const ChecklistReportList = () => import("@/components/admin/checklist-report/index.vue");
const ChecklistReportDetail = () => import("@/components/admin/checklist-report/detail.vue");
const CreateChecklistReport = () => import("@/components/admin/checklist-report/create.vue");
const EditChecklistReport = () => import("@/components/admin/checklist-report/edit.vue");

//question
const QuestionList = () => import("@/components/admin/question/index.vue");
const CreateQuestion = () => import("@/components/admin/question/create.vue");
const EditQuestion = () => import("@/components/admin/question/edit.vue");
const AssignSitesToQuestion = () => import("@/components/admin/question/assign-sites.vue");

// logbook
const LogbookByDateRange  = () => import("@/components/admin/logbook/daterange.vue");
const LogbookByDate  = () => import("@/components/admin/logbook/bydate.vue");
const EditLogbook  = () => import("@/components/admin/logbook/edit.vue");


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Landing,
    name: "Landing",
  },
  {
    path: "/login",
    component: Login,
    name: "Login",
  },
  {
    path: "/change-password",
    component: ChangePassword,
    name: "ChangePassword",
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    name: "forgot-password",
  },
  {
    path: "/reset-password",
    component: ResetPassword,
    name: "ResetPassword",
  },
  {
    path: "/",
    component: HomePage,
    children: [
      {
        path: "/my-attendance",
        component: MyAttendance,
        name: "MyAttendance",
        beforeEnter: checkPermission,
        meta: { permission: "list-user-attendance" },
      },
      {
        path: "my-timesheet",
        component: MyTimesheet,
        name: "MyTimesheet",
        beforeEnter: checkPermission,
        meta: { permission: "list-user-timesheet" },
      },
    ]
  },
  {
    path: "/admin",
    component: HomePage,
    children: [
      {
        path: "",
        component: Dashboard,
        name: "Dashboard",
      },
      {
        path: "timesheet",
        component: TimesheetDateRange,
        name: "TimesheetDateRange",
        beforeEnter: checkPermission,
        meta: { permission: "list-all-timesheet" },
      },
      {
        path: "timesheet/:id/edit",
        component: EditTimesheet,
        name: "EditTimesheet",
        beforeEnter: checkPermission,
        meta: { permission: "edit-timesheet" },
      },

      {
        path: "my-timesheet",
        component: MyTimesheet,
        name: "MyTimesheet",
        beforeEnter: checkPermission,
        meta: { permission: "list-user-timesheet" },
      },
      {
        path: "my-timesheet/create",
        component: CreateTimesheet,
        name: "CreateTimesheet",
        beforeEnter: checkPermission,
        meta: { permission: "add-timesheet" },
      },
      {
        path: "my-timesheet/:id/edit",
        component: EditMyTimesheet,
        name: "EditMyTimesheet",
        beforeEnter: checkPermission,
        meta: { permission: "edit-timesheet" },
      },
      {
        path: "attendance",
        component: AttendanceDateRange,
        name: "Attendance",
        beforeEnter: checkPermission,
        meta: { permission: "list-all-attendances" },
      },
      {
        path: "attendance/:id/edit",
        component: EditAttendance,
        name: "EditAttendance",
        beforeEnter: checkPermission,
        meta: { permission: "edit-attendance" },
      },
      {
        path: "todays-attendance",
        component: TodaysAttendance,
        name: "TodaysAttendance",
        beforeEnter: checkPermission,
        meta: { permission: "list-all-attendances" },
      },
      {
        path: "attendance/deleted-list",
        component: AttendanceDeletedList,
        name: "AttendanceDeletedList",
        beforeEnter: checkPermission,
        meta: { permission: "list-all-attendances" },
      },
      {
        path: "user",
        component: Users,
        name: "Users",
        beforeEnter: checkPermission,
        meta: { permission: "list-user" },
      },
      {
        path: "user/create",
        component: CreateUser,
        name: "CreateUser",
        beforeEnter: checkPermission,
        meta: { permission: "add-user" },
      },
      {
        path: "user/active",
        component: ActiveUsers,
        name: "ActiveUsers",
        beforeEnter: checkPermission,
        meta: { permission: "list-user" },
      },
      {
        path: "user/inactive",
        component: InactiveUsers,
        name: "InactiveUsers",
        beforeEnter: checkPermission,
        meta: { permission: "list-user" },
      },
      {
        path: "user/:id/edit",
        component: EditUser,
        name: "EditUser",
        beforeEnter: checkPermission,
        meta: { permission: "edit-user" },
      },

      {
        path: "user/:id/assign-role",
        component: AssignRole,
        name: "AssignRole",
        beforeEnter: checkPermission,
        meta: { permission: "assign-role" },
      },
      {
        path: "user/:id/assign-site",
        component: AssignSiteToUser,
        name: "AssignSite",
        beforeEnter: checkPermission,
        meta: { permission: "assign-users-to-site" },
      },
      
      {
        path: "user/deleted-list",
        component: DeletedUserList,
        name: "DeletedUserList",
        beforeEnter: checkPermission,
        meta: { permission: "list-deleted-user" },
      },
      {
        path: "change-user-password/:id",
        component: ChangeUserPassword,
        name: "change-user-password",
        beforeEnter: checkPermission,
        meta: { permission: "change-password-admin" },
      },
      {
        path: "role",
        component: RoleList,
        name: "RoleList",
        beforeEnter: checkPermission,
        meta: { permission: "list-role" },
      },
      {
        path: "role/create",
        component: CreateRole,
        name: "CreateRole",
        beforeEnter: checkPermission,
        meta: { permission: "add-role" },
      },
      {
        path: "role/:id/edit",
        component: EditRole,
        name: "EditRole",
        beforeEnter: checkPermission,
        meta: { permission: "edit-role" },
      },
      {
        path: "role/:id/assign-permission",
        component: AssignPermission,
        name: "AssignPermission",
        beforeEnter: checkPermission,
        meta: { permission: "assign-permission" },
      },
      // site
      {
        path: "site",
        component: SiteList,
        name: "Sites",
        beforeEnter: checkPermission,
        meta: { permission: "list-site" },
      },
      {
        path: "site/create",
        component: CreateSite,
        name: "CreateSite",
        beforeEnter: checkPermission,
        meta: { permission: "add-site" },
      },
      {
        path: "site/:id/co-ordinate",
        component: UpdateCoordinate,
        name: "UpdateCoordinate",
        beforeEnter: checkPermission,
        meta: { permission: "update-site-coordinate" },
      },
      {
        path: "site/:id/edit",
        component: EditSite,
        name: "EditSite",
        beforeEnter: checkPermission,
        meta: { permission: "edit-site" },
      },
      {
        path: "site/:id/assign-users",
        component: AssignUsers,
        name: "AssignUsers",
        beforeEnter: checkPermission,
        meta: { permission: "assign-users-to-site" },
      },
      {
        path: "site/:id/site-duty",
        component: SiteDutyList,
        name: "SiteDutyList",
        beforeEnter: checkPermission,
        meta: { permission: "add-update-view-delete-site-duites" },
      },
      {
        path: "site/:id/site-duty/create",
        component: CreateSiteDuty,
        name: "CreateSiteDuty",
        beforeEnter: checkPermission,
        meta: { permission: "add-update-view-delete-site-duites" },
      },
      {
        path: "site-duty/:id/edit",
        component: EditSiteDuty,
        name: "EditSiteDuty",
        beforeEnter: checkPermission,
        meta: { permission: "add-update-view-delete-site-duites" },
      },

      // logbook
      {
        path: "logbook",
        component: LogbookByDateRange,
        name: "LogbookByDateRange",
        beforeEnter: checkPermission,
        meta: { permission: "view-logbook-by-admin" },
      },
      {
        path: "logbook/by-date",
        component: LogbookByDate,
        name: "LogbookByDate",
        beforeEnter: checkPermission,
        meta: { permission: "view-logbook-by-admin" },
      },
      {
        path: "logbook/:id/edit",
        component: EditLogbook,
        name: "EditLogbook",
        beforeEnter: checkPermission,
        meta: { permission: "edit-logbook-by-admin" },
      },
      {
        path: "incident-report",
        component: IncidentReportList,
        name: "ListIncidentReport",
        beforeEnter: checkPermission,
        meta: { permission: "list-incident-report" },
      },
      {
        path: "incident-report/:id",
        component: IncidentReportDetail,
        name: "IncidentReportDetail",
        beforeEnter: checkPermission,
        meta: { permission: "list-incident-report" },
      },

      // checklist report
      {
        path: "checklist-report",
        component: ChecklistReportList,
        name: "ChecklistReportList",
        beforeEnter: checkPermission,
        meta: { permission: "list-checklist-report" },
      },
      {
        path: "checklist-report/create",
        component: CreateChecklistReport,
        name: "CreateChecklistReport",
        beforeEnter: checkPermission,
        meta: { permission: "add-checklist-report" },
      },
      {
        path: "checklist-report/:id",
        component: ChecklistReportDetail,
        name: "ChecklistReportDetail",
        beforeEnter: checkPermission,
        meta: { permission: "list-checklist-report" },
      },
      {
        path: "checklist-report/:id/edit",
        component: EditChecklistReport,
        name: "EditChecklistReport",
        beforeEnter: checkPermission,
        meta: { permission: "edit-checklist-report" },
      },

      // question
      {
        path: "question",
        component: QuestionList,
        name: "QuestionList",
        beforeEnter: checkPermission,
        meta: { permission: "list-checklist-question" },
      },
      {
        path: "question/create",
        component: CreateQuestion,
        name: "CreateQuestion",
        beforeEnter: checkPermission,
        meta: { permission: "add-checklist-question" },
      },
      {
        path: "question/:id/edit",
        component: EditQuestion,
        name: "EditQuestion",
        beforeEnter: checkPermission,
        meta: { permission: "edit-checklist-question" },
      },
      {
        path: "question/:id/assign-sites",
        component: AssignSitesToQuestion,
        name: "AssignSitesToQuestion",
        beforeEnter: checkPermission,
        meta: { permission: "add-checklist-question" },
      },
      {
        path: "/unauthorized",
        component: Unauthorized,
        name: "Unauthorized",
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

let allowedPath = ["Login", "ResetPassword", "forgot-password"];
router.beforeEach((to, from, next) => {
  let isAuthenticated = sessionStorage.getItem("token");
  if (allowedPath.indexOf(to.name) != -1 && isAuthenticated)
    next({ name: "dashboard" });
  if (allowedPath.indexOf(to.name) == -1 && !isAuthenticated)
    next({ name: "Login" });
  else next();
});

async function checkPermission(to, from, next) {
  let permissions = store.state.permissions;
  if (permissions.length == 0) {
    await util
      .http({
        url: "role/permissions/user-permissions",
      })
      .then((resp) => {
        store.state.permissions = permissions = resp.data.data;
      });
  }
  if (permissions.indexOf(to.meta.permission) != -1) {
    return next();
  } else return next({ name: "Unauthorized" });
}

export default router;
