import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    permissions: [],
    profile: null,
    status: [
      {
        label: "Active",
        value: 1,
      },
      {
        label: "Inactive",
        value: 0,
      },
    ],
    refCount: 0,
    // isLoading: false,
  },
  mutations: {
    setPermissions(state, data) {
      return (state.permissions = data);
    },
    // loading(state, isLoading) {
    //   console.log({ isLoading });
    //   if (isLoading) {
    //     state.isLoading = true;
    //   } else {
    //     state.isLoading = false;
    //   }
    // },
    setProfile(state, data) {
      return (state.profile = data);
    }
  },
  actions: {},
  modules: {},
});
